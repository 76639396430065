

































































import { Component, Vue } from "vue-property-decorator";

import { Kopf } from "@/models/Rechnungen/Kopf";
import { VerlagListeModel } from "@/models/VerlagListeModel";
import store from "@/store";
import VerlagsRechnungModule from "@/store/modules/vr";
import { catVue } from "@/util/logging";
import { getModule } from "vuex-module-decorators";

// eslint-disable-next-line

@Component({
  components: {},
})
export default class ListeUnfertigerRechnungen extends Vue {
  //
  drawer = false;
  value = null as null | VerlagListeModel;
  vrState = getModule(VerlagsRechnungModule, store);

  onSelect(event: VerlagListeModel | null): void {
    console.log(event);
    if (event) {
      this.value = event;
      const k = Kopf.make(event);
      catVue.info(`${this.$options.name}:: Mutating ... ${this.value}`);
      this.vrState.updateRechnung(k);
      catVue.info(`${this.$options.name}:: Mutated ... ${this.value}`);
    }
    return;
  }

  created(): void {
    catVue.info(`${this.$options.name}:: CREATED ... ${this.value}`);
    catVue.info(`${this.$options.name}:: -> ${this.vrState.aktuelleRechnung}`);
    console.log(this.vrState);
    //catVue.info(`${this.$options.name}:: -> ${MyJSON.out(this.vrState)}`);
    catVue.info(`${this.$options.name}:: -> ${this.vrState.rechnungen.length}`);
  }

  isActive(kopf: Kopf): boolean {
    catVue.info(
      `isActive -> ${kopf?.uid} --> ${this.vrState.aktuelleRechnung?.uid} --> ${
        kopf?.uid === this.vrState.aktuelleRechnung?.uid
      }`
    );
    return kopf?.uid === this.vrState.aktuelleRechnung?.uid;
  }

  deleteEintrag(kopf: Kopf): void {
    catVue.info(
      `delete -> ${kopf?.uid} --> ${this.vrState.aktuelleRechnung?.uid} --> ${
        kopf?.uid === this.vrState.aktuelleRechnung?.uid
      }`
    );
    this.vrState.removeRechnung(kopf);
    catVue.info(
      `delete -> ${kopf?.uid} --> ${this.vrState.aktuelleRechnung?.uid} --> ${
        kopf?.uid === this.vrState.aktuelleRechnung?.uid
      }`
    );
  }

  selectEintrag(kopf: Kopf): void {
    catVue.info(
      `select -> ${kopf?.uid} --> ${this.vrState.aktuelleRechnung?.uid} --> ${
        kopf?.uid === this.vrState.aktuelleRechnung?.uid
      }`
    );
    this.vrState.updateRechnung(kopf);
    catVue.info(
      `select -> ${kopf?.uid} --> ${this.vrState.aktuelleRechnung?.uid} --> ${
        kopf?.uid === this.vrState.aktuelleRechnung?.uid
      }`
    );
  }
}
